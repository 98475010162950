import { Component, OnInit, AfterViewInit,  Renderer2, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';

import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from 'angularfire2/database';
import { FirebaseListObservable, FirebaseObjectObservable } from 'angularfire2/database-deprecated';

import { Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '../auth/auth.service';
import { SessionService } from '../session/session.service';
import { Session } from '../session/session.model';
import { Users } from '../users/users.model';

@Component({
  selector: 'app-mentor-match',
  templateUrl: './mentor-match.component.html',
  styleUrls: ['./mentor-match.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MentorMatchComponent implements OnInit, AfterViewInit {

  @ViewChild('tborder')
  @ViewChild('theading')
  @ViewChild('partnerDescription')

  private tborder: ElementRef;
  private theading: ElementRef;
  private partnerDescription: ElementRef;

  containerHeight:number;
  borderHeight:number;
  headingHeight:number;

  session_id: string;
  question_id: string;
  currentQuestion = '';
  currentResponseList: Array<any>;

  responseAnswers: Array<any> = [];
  percentageResults: Array<any> = [];

  itemsRef = this.db.list('/sessions');

  activePerson = {};

  partners = [{
  	id:'a', //was b
  	name:'Linda Connelly',
  	practice:'Dept: International Business',
  	basic:'Female, Age 47, B.A. Ohio State, J.D. University of Virginia Law',
  	bio:'Former staff attorney for INS Immigration and Customs Envorcement. Wants to grow immigration department.  35% of clients are Spanish speaking (currently, none of the four attorneys in the group speaks Spanish). Hours are generally steady except for certain government established application deadlines. Associates will prepare and take administrative appeals as necessary.',
  	newBio:['Former staff attorney for a Fortune 500 Company','Wants to grow international business department','35% of clients are Spanish speaking (currently, none of the four attorneys in the group speaks Spanish)','Enjoys golfing and skiing','Needs associate who can travel overseas'],
    type:'partner',
    headshot:'assets/img/MentorE-headshot.png'
  },
  {
  	id:'b', //was a
  	name:'Stewart Sharpe III',
  	practice:'Dept: Complex Litigation',
  	basic:'Male, Age 55, B.A. Princeton, J.D. Yale Law',
  	bio:'Well recognized, considered a brilliant litigator past President of the Princeton Club, and President of the Academy of Trial Lawyers. Avid golfer. Pedantic mentoring style, very demanding. Expects work to be a priority, has a practice that requires a lot of travel. Has trouble keeping associates, but those who survive his tutelage do extremely well. Has complained that the firm does not recruit enough from the Ivy League.',
  	newBio:['Well recognized, considered a brilliant litigator', 'Past President of the Princeton Club and President of the Academy of Trial Lawyers','Pedantic mentoring style, very demanding, expects work to be the priority','Has a practice that requires a lot of travel','Has trouble keeping associates but those who survive his tutelage do extremely well','Has complained the firm does not recruit enough from the Ivy League'],
    type:'partner',
    headshot:'assets/img/MentorA-headshot.png'
  },
  {
  	id:'c', //was e
  	name:'Joseph Boyd',
  	practice:'Dept: Small Business/Real Estate',
  	basic:'Male, Age 45, B.A. Washington & Jefferson College, J.D. Duquesne Law',
  	bio:'Married with 2 children. Played football at W&J. After law school worked a large real estate development firm. Recently installed as head of hte department and wants to build the practice, which has doubled since he took over. Given local nature of real estate practice, wants good lawyers with strong personality.',
  	newBio:['Married with 2 children','Played football at W & J','After law school, worked at a large real estate development firm','Recently installed as head of the department and wants to build the practice, which has doubled since he took over','Coaches his kids in local sports programs','Given local nature of real estate practice, he wants good lawyers with strong personality'],
    type:'partner',
    headshot:'assets/img/MentorB-headshot.png'
  },
  {
  	id:'d', //was c
  	name:'Roger Worley',
  	practice:'Dept: Mergers & Acquisitions',
  	basic:'Male, Age 60, B.S. in International Studies from Johns Hopkins, J.D. Georgetown Law',
  	bio:'Divorced three times. Hard charging, demanding personality. Has survived the rigors of a long mergers and acquisition practice by being singularly focused on work, which requires extensive travel for long periods of time. Those who have worked well with him have tended to either not have families or were willing to put in the long hours. Has received recognition for grasphing the details of international treaties and taxation implications in mergers.',
  	newBio:['Divorced three times','Hard charging, demanding personality, who has survived the rigors of a long mergers and acquisition practice by being singularly focused on work','Those who have worked well with him have tended to either not have families or were willing to put in the long hours','Served as marine'],
    type:'partner',
    headshot:'assets/img/MentorC-headshot.png'
  },
  {
  	id:'e', //was d
  	name:'Marc Crow',
  	practice:'Dept: Labor / Employment',
  	basic:'Male, Age 65, B.A. Ole Miss, J.D. Vanderbilt Law',
  	bio:'Church deacon, married for 40 years. Former in-house counsel at a Fortune 500 company where he defended employment cases mostly brought by women and minorities. Feels strongly based on experience with juries that he needs to develop an African-American woman to serve as trial counsel and the face of defendant corporations. Also values in-house corporate experience. Complained that a recently departed associate had not been well trained, having worked in a large government bureaucracy.',
  	newBio:['Church Deacon, married 40 years','Former in-house counsel at a Fortune 500 company where he defended employment cases mostly brought by women and minorities','Feels strongly based on experience with juries that he needs to develop a diverse woman to serve as trial counsel and the face of defendant corporations','Values in-house corporate experience','Complained a recently departed associate had not been well trained, having worked in a large government bureaucracy'],
    type:'partner',
    headshot:'assets/img/MentorD-headshot.png'
  },
  {
  	id:'cassandra',
  	name:'Cassandra Parker',
  	practice:'',
  	basic:'Female, Age 30, B.A. in Accounting from Cornell, J.D. University of Pittsburgh Law',
  	bio:'Interested in small busiess and corporate transactions. Prior to law school worked for HUD reviewing past real estate red-lining practices. Effective witness in a trial HUD brought against a real estate developer. Also had a good experience as a law intern working on a small employment trial.',
  	type:'mentee',
    headshot:'assets/img/cassandra-headshot.png',
    newBio:['Interested in small business and corporate transactions','Prior to law school worked for HUD reviewing past real estate red-lining practices','Effective witness in a trial HUD brought against a real estate developer','She had a great experience as a law intern working on a small employment trial','Advisory Board, Planned Parenthood','Single']
  },
  {
  	id:'hannah',
  	name:'Hannah Weaver',
  	practice:'',
  	basic:'Female, Age 32, B.A. Middlebury College, J.D. Georgetown Law',
  	bio:'Single mohter of 3 young children. Wrote a law review article on European Union Membership Requirements. Interested in practicing in international transaction and complex transactions. Fluent in French and German. Worked in Peace Corp in Morocco. Ambitious but has expressed concerns about balancing work with family.',
  	type:'mentee',
    headshot:'assets/img/hannah-headshot.png',
    newBio:['Wrote a law review article on European Union Membership Requirements','Interested in practicing in international transaction and complex transactions','Fluent in French and German','Worked in Peace Corp in Morocco','Ambitious','Single mother of 3 young children']
  },
  {
  	id:'chad',
  	name:'Chad Silverman',
  	practice:'',
  	basic:'Male, Age 27, B.A. Bucknell, J.D. Penn State Dickinson Law',
  	bio:'Played lacrosse at Bucknell and was President of his fraternity. Is very interested in labor law. After college worked for two years in the Governor\'s Office serving as a political liaison to the Department of Labor. Uncle is a local real estate mogul who currently uses another firm. Becoming a skilled litigator. Eventually hopes to get involved in politics.',
  	type:'mentee',
    headshot:'assets/img/chad-headshot.png',
    newBio:['Played lacrosse at Bucknell and was President of his fraternity.','Really likes and is interested in labor law','After college worked for two years in the Governor’s Office serving as political liaison to the Department of labor','Uncle is a local real estate mogul who currently uses another firm','He is becoming a skilled litigator and eventually hopes to get involved in politics','Fluent in Spanish','Single'
]
  },
  {
  	id:'juan',
  	name:'Juan Santos',
  	practice:'',
  	basic:'Male, Age 35, B.A. in Engineering at Mullenberg College, J.D. Univeristy of Miami',
  	bio:'Married with 4 children. Served in military in Iraq before college. Fluent in Spanish. Received scholarships for both college and law school. Debate champion. At Miami won the moot court competition defeating the Columbia Law team in the finals. Very interested in becoming a trial lawyer and complex litigation.',
  	type:'mentee',
    headshot:'assets/img/juan-headshot.png',
    newBio:['Served in military in Iraq before college','Worked for World Trade Organization','Fluent in Spanish, received scholarships for both college and law school, debate champion','Won the Moot Court competition at Miami defeating the Columbia Law team in the finals','Interested in litigation','Avid football fan','Married with 4 children']
  },
  {
  	id:'michael',
  	name:'Michael Zhang',
  	practice:'',
  	basic:'Male, Age 26, B.A. Lehigh University, MBA Wharton School at Penn, J.D. Duke Law',
  	bio:'Third generation American, fluent in Mandarin. At Lehigh and Penn, focused on economics and international finance issues and wrote an article comparing Chinese and U.S. real estate tax treatment. At Duke was inspired by a Constitutional Law professor and interned in Washington D.C. with an appellate practice firm focusing on money laundering. Wants to return with his wirfe to Pennsylvania and is very interested in a litigation career. Thinks his understanding of finance will be a real asset.',
  	type:'mentee',
    headshot:'assets/img/michael-headshot.png',
    newBio:['At Lehigh and Penn, he focused on economics and international finance issues','Wrote article comparing Chinese and U.S. real estate tax treatment','Fluent in Mandarin','At Duke, he was inspired by a Constitutional Law professor and interned in Washington D.C. with an appellate practice firm focusing on money laundering','He and his wife want to return to Pennsylvania','On college golf team']
  }
  ];

  constructor(private renderer:Renderer2,public db: AngularFireDatabase,public router: Router, route: ActivatedRoute,private elementRef:ElementRef) { 
  	this.renderer.addClass(document.body, 'mentor-match');

  	route.params.forEach((urlParameters) => {
     	this.session_id = urlParameters['id'];
     	this.question_id = 'mm';
    });


    //var offsetHeight = document.getElementById("tborder").offsetHeight + document.getElementById("theading").offsetHeight;
  	//var remainingHeight = window.innerHeight - offsetHeight;

    //this.renderer.setStyle(document.getElementById("partnerDescription"), "min-height",remainingHeight+"px");

  	//console.log(this.percentageResults);

    //Set CURRENT QUESTION as first one when constructed.
    this.currentQuestion = this.question_id;

  	this.itemsRef.update(this.session_id, { isQuestionsActive: false, currentQuestion: this.currentQuestion });

  	//Show Current Vote Tally.. Obtain Responses and format for result output.
  	this.db.list('/responses', ref=> ref.orderByChild('session').equalTo(this.session_id)).valueChanges(['child_added','child_changed']).subscribe(queriedItems => {
			//this.userCount = queriedItems.length;
			//console.log(queriedItems);
			//console.log(this.currentQuestion);
			this.currentResponseList = [];
			this.responseAnswers = [];
			this.responseAnswers.push(new Array(new Array(), new Array(),new Array(), new Array(),new Array()),
  							  new Array(new Array(), new Array(),new Array(), new Array(),new Array()),
  							  new Array(new Array(), new Array(),new Array(), new Array(),new Array()),
  							  new Array(new Array(), new Array(),new Array(), new Array(),new Array()),
  							  new Array(new Array(), new Array(),new Array(), new Array(),new Array())
  							 );
			this.percentageResults = [];
		  	this.percentageResults.push(new Array(new Array()),
		  		new Array(new Array()),
		  		new Array(new Array()),
		  		new Array(new Array()),
		  		new Array(new Array())
		  		);

			queriedItems.forEach(element => {
				if(element['questionid'] == 'mm'){
					this.currentResponseList.push(element);
				}
			});

			//console.log(this.currentResponseList);

			//One iteration for each mentor... 
			for(let i=0; i<5; i++){
				//Loop through each answer.  Then loop through all the answers for each of the five students. 
				//for each mentor, i is an item... 

				this.currentResponseList.forEach(item=>{

					item.answer.forEach(ansitem => {

						this.responseAnswers[i][ansitem.student].push(ansitem.answer);	

					});

				});
			}

			this.responseAnswers.forEach((item,index)=>{

				item.forEach((elem, idex)=>{
					if(index === 0){
						var numAs = elem.reduce(function (n, answers) {
							    return n + (answers == 'a');
							}, 0);
						var percent = numAs/elem.length;
						this.percentageResults[index][idex] = percent;
					}
					if(index === 1){
						var numBs = elem.reduce(function (n, answers) {
							    return n + (answers == 'b');
							}, 0);
						var percent = numBs/elem.length;
						this.percentageResults[index][idex] = percent;
					}
					if(index === 2){
						var numCs = elem.reduce(function (n, answers) {
							    return n + (answers == 'c');
							}, 0);
						var percent = numCs/elem.length;
						this.percentageResults[index][idex] = percent;
					}
					if(index === 3){
						var numDs = elem.reduce(function (n, answers) {
							    return n + (answers == 'd');
							}, 0);
						var percent = numDs/elem.length;
						this.percentageResults[index][idex] = percent;
					}
					if(index === 4){
						var numEs = elem.reduce(function (n, answers) {
							    return n + (answers == 'e');
							}, 0);
						var percent = numEs/elem.length;
						this.percentageResults[index][idex] = percent;
					}
				});
				
			});

			//console.log(this.currentResponseList);
			//console.log(this.responseAnswers);
			//console.log(this.percentageResults);
		
	});
    
  }

  ngOnInit() {
  }

  ngAfterViewInit(){
    

  }

  start(){
  	this.currentQuestion = 'mm-department-heads';
  	this.itemsRef.update(this.session_id, { isQuestionsActive: true, currentQuestion: this.currentQuestion });
    

    setTimeout(() => {
        this.containerHeight = this.elementRef.nativeElement.offsetHeight;
        this.borderHeight = this.elementRef.nativeElement.offsetHeight;
        this.headingHeight = this.elementRef.nativeElement.offsetHeight;

        var screenH = window.innerHeight;
        var pDescH = screenH - (document.getElementById("tborder").offsetHeight + document.getElementById("theading").offsetHeight);
        document.getElementById("partnerDescription").style.height = (pDescH-10)+"px";
        
    }, 100);

    
  }
  onResize(){
    var screenH = window.innerHeight;
    var pDescH = screenH - (document.getElementById("tborder").offsetHeight + document.getElementById("theading").offsetHeight);
    document.getElementById("partnerDescription").style.height = (pDescH-10)+"px";
  }

  showResults(){
  	this.currentQuestion = 'mm-show-results';
  	this.itemsRef.update(this.session_id, { isQuestionsActive: false, currentQuestion: this.currentQuestion });
  }

  showPerson(person){
  	var inscontainer = document.getElementById('inst-cont');
  	var imgHolder = document.getElementById('img-holder');

  	this.partners.forEach((itemhere) => {
    	if(itemhere.id == person){
    		this.activePerson = itemhere;

    		//console.log(this.activePerson);
    	}

     	//this.session_id = urlParameters['id'];
     	//this.question_id = 'mm';
    });

  	this.renderer.addClass(inscontainer, 'inactive');
  	//this.renderer.setStyle(imgHolder, 'background-image','url("/assets/img/'+person+'-INFO.png")')
  	this.renderer.addClass(imgHolder, 'active');
  }

  goToMenu(){
	let newURL = '/admin/'+this.session_id;
	this.renderer.removeClass(document.body, 'mentor-match');
	
	this.itemsRef.update(this.session_id, { isQuestionsActive: false, currentQuestion: this.currentQuestion });
  

	this.router.navigateByUrl( newURL );
  }

}
