import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';

import { Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

import {AuthService} from '../auth/auth.service';

import { SessionService } from '../session/session.service';
import { Session } from '../session/session.model';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  title = 'Admin Area';

  user = {
    email:'',
    password:'',
  }

  public sessions: Observable<any[]>;

  newURL = '';
  error = '';
  userRef: AngularFireList<any>;
  sessionList: Session[];
  sessionItem: AngularFireList<any>;

  constructor(public db: AngularFireDatabase,public router: Router, route: ActivatedRoute, public authService: AuthService, private sessionService: SessionService) {
       this.userRef = db.list('/users');
  }

  signInWithEmail() {
    this.authService.signInRegular(this.user.email, this.user.password)
      .then((res) => {
         /*console.log(res);
         console.log(this.authService.isAdminLoggedIn);
         console.log(this.authService);

         console.log(this.authService.authState);
         console.log(this.authService.authState.user);*/

         if(this.authService.authState !== null && this.authService.authState.email !== ''){
           this.router.navigate(['admin-create-session']);
         } else if(this.authService.authState !== null && this.authService.authState.user.email !== ''){
           this.router.navigate(['admin-create-session']);
         } else {
           this.error = 'Please provide a valid username and password.';
         }
      })
      .catch((err) => console.log('error: ' + err));
  }

  login(session) {
  	this.authService.anonymousLogin().then((result) => {
        //Add user to list with session ID

        //console.log(this.authService.userInfo);
  
		    this.sessionItem.update(session,{ admin:this.authService.userInfo.user.uid,isActive:true });

      	//Setup New URL.
      	this.newURL = '/admin/'+session;

      	this.router.navigateByUrl( this.newURL , { skipLocationChange:false } );

    });
  }

  ngOnInit() {
  	this.sessionItem = this.sessionService.getData();

  	var x = this.sessionService.getData();
    //console.log(x);
    x.snapshotChanges().subscribe(item=>{
      this.sessionList = [];
      item.forEach(element => {
         var y = element.payload.toJSON();
         y["$key"] = element.key;
         this.sessionList.push(y as Session);
      });
    });
  }

  ngOnChanges(changes) {
  	//console.log(changes);
    //this.session_ID.id = this.session_ID;
  }

}
