import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import { Observable } from 'rxjs/Observable';
import {AngularFireAuth} from 'angularfire2/auth';

import * as firebase from 'firebase/app';
//import { Observable } from 'rxjs/Observable';

@Injectable()
export class AuthService {

  public authState: any = null;

  userInfo: any = null;

  constructor(public afAuth: AngularFireAuth, private router: Router) {
    this.afAuth.authState.subscribe((auth) => {
      this.authState = auth
    });
  }

  get isUserAnonymousLoggedIn(): boolean {
    //console.log(this.authState);
    return (this.authState !== null) ? this.authState.isAnonymous : false
  }

  get isAdminLoggedIn(): boolean {

    if(this.authState !== null){
      if(this.authState !== null && this.authState.email){
        return (this.authState !== null) ? this.authState.email : false
      } else if(this.authState !== null && this.authState.user.email !== ''){
        return (this.authState !== null) ? this.authState.user.email : false
      }
    }
    
  }

  get currentUserId(): string {
    return (this.authState !== null) ? this.authState.uid : '';
  }

  testcurrentUserId(): string {
    return (this.authState !== null) ? this.authState.uid : '';
  }

  signInRegular(email, password) {
    const credential = firebase.auth.EmailAuthProvider.credential( email, password );
    return this.afAuth.auth.signInWithEmailAndPassword(email, password).then((user) => {
      this.authState = user;
      this.userInfo = user;
      //console.log("THIS IS THE AUTH STATE:")
      //console.log(this.authState);
    }).catch(error => console.log(error));
  }

  anonymousLogin() {
    return this.afAuth.auth.signInAnonymously()
      .then((user) => {
        this.authState = user;
        this.userInfo = user;
        //console.log(this.authState);
      })
      .catch(error => console.log(error));
  }

  signOut(): void {
    this.afAuth.auth.signOut();
    this.router.navigate(['/admin']);
  }
  userSignOut(): void {
    this.afAuth.auth.signOut();
    this.router.navigate(['/']);
  }
  adminUserSignOut(): void {
    this.afAuth.auth.signOut();
    this.router.navigate(['/admin']);
  }
}