import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

//You need to load all your components.  Think of these as controllers / bits of code and corresponding markup
import { UsersComponent }      from './users/users.component';
import { SessionusersComponent }      from './sessionusers/sessionusers.component';
import { AdminComponent }      from './admin/admin.component';
import { AdminSessionComponent } from './admin-session/admin-session.component';
import { AdminStartSessionComponent } from './admin-start-session/admin-start-session.component';
import { AdminCreateSessionComponent } from './admin-create-session/admin-create-session.component';
import { AuthenticationGuard } from './auth/authentication.guard'

//SCENARIOS
import { Scenario1Component } from './scenario1/scenario1.component';
import { MentorMatchComponent } from './mentor-match/mentor-match.component';

const routes: Routes = [
  //Guarded route
  { path: 'admin-create-session', component: AdminCreateSessionComponent, canActivate: [AuthenticationGuard] },
  { path: 'admin-start-session', component: AdminStartSessionComponent, canActivate: [AuthenticationGuard] },
  //This route handles the mentor match scenario
  { path: 'admin/:id/mm', component: MentorMatchComponent, canActivate: [AuthenticationGuard] },
  //The route below will handle all the video scenes.
  { path: 'admin/:id/:scene', component: Scenario1Component, canActivate: [AuthenticationGuard] },
  //This is the unique session identifier.
  { path: 'admin/:id', component: AdminSessionComponent },
  //Admin landing page provides login form.
  { path: 'admin', component: AdminComponent },
  //Should also guard this route, this is where users are taken.
  { path: 'session/:id', component: SessionusersComponent, canActivate: [AuthenticationGuard] },

  //Testing and debugging: 
  { path: 'users/:id', component: UsersComponent},

  //Default route if nothing is specified or route is wrong. 
  { path: '', component: UsersComponent }
];


@NgModule({
  exports: [RouterModule],
  imports: [ RouterModule.forRoot(routes) ],
})
export class AppRoutingModule { }

