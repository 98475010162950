import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip'

import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFireAuthModule } from 'angularfire2/auth';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './/app-routing.module';
import { environment } from '../environments/environment';

import { AuthService } from './auth/auth.service';
import { AuthenticationGuard } from './auth/authentication.guard';

//Login session handling and controlling the state of the application.
import { UsersComponent } from './users/users.component';
import { SessionusersComponent } from './sessionusers/sessionusers.component';
import { AdminComponent } from './admin/admin.component';
import { AdminSessionComponent } from './admin-session/admin-session.component';
import { SessionComponent } from './session/session.component';
import { AdminCreateSessionComponent } from './admin-create-session/admin-create-session.component';
import { AdminStartSessionComponent } from './admin-start-session/admin-start-session.component';

//VIDEO STUFF
import {VgCoreModule} from 'videogular2/core';
import {VgControlsModule} from 'videogular2/controls';
import {VgOverlayPlayModule} from 'videogular2/overlay-play';
import {VgBufferingModule} from 'videogular2/buffering';

//Scenario controllers.
import { Scenario1Component } from './scenario1/scenario1.component';
import { MentorMatchComponent } from './mentor-match/mentor-match.component';

@NgModule({
  declarations: [
    AppComponent,
    UsersComponent,
    SessionusersComponent,
    AdminComponent,
    AdminSessionComponent,
    SessionComponent,
    AdminCreateSessionComponent,
    AdminStartSessionComponent,
    Scenario1Component,
    MentorMatchComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    AppRoutingModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule
  ],
  providers: [AuthService, AuthenticationGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
