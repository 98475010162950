import { Component, Input, OnInit, OnChanges, OnDestroy, Renderer2, ViewEncapsulation, EventEmitter, Output } from '@angular/core';

import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from 'angularfire2/database';
import { FirebaseListObservable, FirebaseObjectObservable } from 'angularfire2/database-deprecated';

import { Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '../auth/auth.service';
import { SessionService } from '../session/session.service';
import { Session } from '../session/session.model';
import { Users } from '../users/users.model';

import { VgAPI } from 'videogular2/core';

import { Question } from './question.model';



@Component({
  selector: 'app-scenario1',
  templateUrl: './scenario1.component.html',
  styleUrls: ['./scenario1.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class Scenario1Component implements OnInit {

  preload:string = 'auto';

  sources : Array<Object>;
  public session: Observable<any>;
  public questions: Observable<any[]>;
  session_id: string;
  question_id: string;

  @Input() public responses: Observable<any[]>;

  firstVideo: string;
  secondVideo: string;

  currentVideo = '';
  lastVideo = '';
  currentQuestion = '';
  isQuestionDisplayed = 0;

  currentResponseList: Array<any>;
  voteResultsPercentage: Array<any>;

  showVoteResults = '';

  //This hold all questions in firebase.
  questionList: Question[];

  //This is used to populate component template with current question.
  questionObject: Array<any>;

  itemsRef = this.db.list('/sessions');

  responseRef = this.db.list('/responses');

  constructor(public api:VgAPI, private renderer:Renderer2,public db: AngularFireDatabase,public router: Router, route: ActivatedRoute) {
  	//console.log(api);

    //GET ROUTE PARAMETER for session.
    route.params.forEach((urlParameters) => {
    	//console.log(urlParameters);
     	this.session_id = urlParameters['id'];
     	this.question_id = urlParameters['scene'];
    });

    //Setup videos for scenario.
    if(this.question_id == 's2'){
    	this.firstVideo = 'assets/videos/'+this.question_id+'-p1.mp4';

    }else{
    	this.firstVideo = 'assets/videos/'+this.question_id+'-p1.mp4';
  		this.secondVideo = 'assets/videos/'+this.question_id+'-p2.mp4';
    }

    //Set CURRENT QUESTION as first one when constructed.
    this.currentQuestion = this.question_id+'_q1';

    //Load the video.  *ngFOR is the only way the sources can be swapped.
  	this.sources = new Array<Object>();
    this.sources.push({
      src: this.firstVideo,
      type: 'video/mp4'
    });

    this.lastVideo = this.firstVideo;
    this.currentVideo = this.firstVideo;

    this.showVoteResults = '0';

    //GET SESSION INFORMATION 
    this.session = db.list('/sessions', ref=> ref.orderByKey().equalTo(this.session_id)).valueChanges();

    this.itemsRef.update(this.session_id, { isQuestionsActive: false, currentQuestion: this.currentQuestion });
    

    this.db.list('/responses', ref=> ref.orderByChild('session').equalTo(this.session_id)).valueChanges(['child_added','child_changed']).subscribe(queriedItems => {
			//this.userCount = queriedItems.length;
			console.log(queriedItems);
			console.log(this.currentQuestion);

			this.currentResponseList = [];

			queriedItems.forEach(element => {
        console.log(element);
				if(element['questionid'] == this.currentQuestion){
					this.currentResponseList.push(element);
          console.log('should have pushed');
          console.log(this.currentResponseList);
				}else{
          console.log(element['questionid']);
          console.log(this.currentQuestion);
        }
			});

      console.log('AFTER LOOP');
      console.log(this.currentResponseList);
      console.log('END AFTER LOOP');

			this.voteResultsPercentage = [];

			var numAs = this.currentResponseList.reduce(function (n, answers) {
			    return n + (answers.answer == 'a');
			}, 0);
			var numBs = this.currentResponseList.reduce(function (n, answers) {
			    return n + (answers.answer == 'b');
			}, 0);
			var numCs = this.currentResponseList.reduce(function (n, answers) {
			    return n + (answers.answer == 'c');
			}, 0);
			var numDs = this.currentResponseList.reduce(function (n, answers) {
			    return n + (answers.answer == 'd');
			}, 0);
      var numEs = this.currentResponseList.reduce(function (n, answers) {
          return n + (answers.answer == 'e');
      }, 0);

			this.voteResultsPercentage.push(
				{
					answer:"a",
					percent:numAs/this.currentResponseList.length,
					value:numAs
				},
				{
					answer:"b",
					percent:numBs/this.currentResponseList.length,
					value:numBs
				},
				{
					answer:"c",
					percent:numCs/this.currentResponseList.length,
					value:numCs
				},
				{
					answer:"d",
					percent:numDs/this.currentResponseList.length,
					value:numDs
				},
        {
          answer:"e",
          percent:numEs/this.currentResponseList.length,
          value:numEs
        }

			);

			//console.log(numAs);
			//console.log(numBs);
			//console.log(numCs);
			//console.log(numDs);


			console.log(this.currentResponseList);
			console.log(this.voteResultsPercentage);
	});
    //api.onPlayerReady(api);
    console.log(this.lastVideo);
    console.log(this.currentVideo);

  }

  ngOnInit() {
  	//GET QUESTIONS
  	this.db.list('questions').snapshotChanges(['child_changed','child_added']).subscribe(item=>{
      this.questionList = [];
      item.forEach(element => {
         var y = element.payload.toJSON();
         //console.log(y);
         y["$key"] = element.key;
         this.questionList.push(y as Question);
         //console.log(this.questionList);
      });
      console.log("///////////////// INITIALIZATION ////////////////////");
      console.log(this.questionList);
      console.log("CURRENT QUESTION:",this.currentQuestion);
      console.log("LAST VIDEO:",this.lastVideo);
      console.log("CURRENT VIDEO:",this.currentVideo);
      console.log("///////////////// END INITIALIZATION ////////////////////");

    });
  	

  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'question-display');
  }

  toggleQuestion(){
  	//console.log(this.api);
  	// db: AngularFireDatabase
  	this.itemsRef.update(this.session_id, { isQuestionsActive: true, currentQuestion: this.currentQuestion });
  	console.log(this.questionList);

  	//GETS CURRENT QUESTION
  	console.log(this.currentQuestion);
  	const qholder = this.questionList.find( i => i.$key === this.currentQuestion );

  	this.questionObject = [];
  	let respHolder = [];

  	let ansProps = Object.keys(qholder.answers);
  	//let ansProps = Object.values(qholder.answers);

  	for (let ans of ansProps) { 
  	    respHolder.push(qholder.answers[ans]);
  	}

  	qholder.answers = respHolder;

  	this.questionObject.push(qholder);

  	console.log(this.questionObject);

  	this.renderer.addClass(document.body, 'question-display');
  	this.isQuestionDisplayed = 1;


	  console.log("///////////////// TOGGLE QUESTION FIRED ////////////////////");
    console.log(this.questionList);
    console.log("CURRENT QUESTION:",this.currentQuestion);
    console.log("CURRENT QUESTION OBJECT:", this.questionObject);
    console.log("LAST VIDEO:",this.lastVideo);
    console.log("CURRENT VIDEO:",this.currentVideo);
    console.log("///////////////// END TOGGLE QUESTION ////////////////////");
  }

  goToMenu(){
  	let newURL = '/admin/'+this.session_id;
  	this.renderer.removeClass(document.body, 'question-display');
  	this.isQuestionDisplayed = 0;
  	this.itemsRef.update(this.session_id, { isQuestionsActive: false, currentQuestion: this.currentQuestion });
    

  	this.router.navigateByUrl( newURL );
  }

  nextQuestion(){
  	//console.log(this.currentQuestion);
  	if(this.currentQuestion == 's1_q1'){
  		this.currentQuestion = 's1_q2';
  	}else if(this.currentQuestion == 's1_q2'){
  		this.currentQuestion = '';
  	}
  	//console.log(this.currentQuestion);

  	if(this.currentQuestion == 's2_q1'){
      this.currentQuestion = 's2_q2';
  	}else if(this.currentQuestion == 's2_q2'){
      this.currentQuestion = '';
    }
  	/*if(this.currentQuestion == 's3_q1'){
  		this.currentQuestion = '';
  	}*/

    if(this.currentQuestion == 's3_q1'){
      this.currentQuestion = 's3_q2';
    }else if(this.currentQuestion == 's3_q2'){
      this.currentQuestion = '';
    }

    if(this.currentQuestion == 's4_q1'){
      this.currentQuestion = 's4_q2';
    }else if(this.currentQuestion == 's4_q2'){
      this.currentQuestion = '';
    }

  	//console.log(this.currentQuestion);
  }

  previousQuestion(){
  	if(this.currentQuestion == 's1_q2'){
  		this.currentQuestion = 's1_q2';
  	}else if(this.currentQuestion == 's1_q1'){
  		this.currentQuestion = 's1_q1';
  	}

    if(this.currentQuestion == 's2_q1'){
      this.currentQuestion = 's2_q1';
    }

  	if(this.currentQuestion == 's3_q2'){
  		this.currentQuestion = 's3_q2';
  	}else if(this.currentQuestion == 's3_q1'){
  		this.currentQuestion = 's3_q1';
  	}

    if(this.currentQuestion == 's4_q2'){
      this.currentQuestion = 's4_q2';
    }else if(this.currentQuestion == 's4_q1'){
      this.currentQuestion = 's4_q1';
    }

  	

  }

  backToQuestion(){
  	this.toggleQuestion();
  }

  replayLastVideo(){
  	this.renderer.removeClass(document.body, 'question-display');
  	this.isQuestionDisplayed = 0;
  	this.showVoteResults = '0';
  	
  	this.api.pause();
  	this.previousQuestion();
  	//this.api.getDefaultMedia().currentTime = 0;

  	//Strip body class
  	

  	//RESET USERS INFO
  	this.itemsRef.update(this.session_id, { isQuestionsActive: false, currentQuestion: this.currentQuestion });

  	//console.log(this.sources);
    if(this.currentQuestion == 's4_q2' || this.currentQuestion == 's4_q2'){
      this.lastVideo = this.secondVideo;
    }

    if(this.currentQuestion == 's3_q2' || this.currentQuestion == 's3_q2'){
      this.lastVideo = this.secondVideo;
    }

    if(this.currentQuestion == 's1_q2'){
      this.lastVideo = this.secondVideo;
    }

  	this.sources = new Array<Object>();
	this.sources.push({
	  src: this.lastVideo,
	  type: 'video/mp4'
	});

	this.api.getDefaultMedia().subscriptions.canPlay.subscribe(
		    	() => {
		    		this.api.play();
		    	});

  }

  goToNextVideo(){
  	this.renderer.removeClass(document.body, 'question-display');
  	this.isQuestionDisplayed = 0;
  	this.showVoteResults = '0';
  	
  	this.api.pause();
  	this.nextQuestion();
  	//this.api.getDefaultMedia().currentTime = 0;

  	//Strip body class
  	

  	//RESET USERS INFO
  	this.itemsRef.update(this.session_id, { isQuestionsActive: false, currentQuestion: this.currentQuestion });

  	//console.log(this.sources);

  	this.sources = new Array<Object>();
	this.sources.push({
	  src: this.secondVideo,
	  type: 'video/mp4'
	});

	//console.log(this.sources);

	 this.db.list('/responses', ref=> ref.orderByChild('session').equalTo(this.session_id)).valueChanges(['child_added','child_changed']).subscribe(queriedItems => {
			//this.userCount = queriedItems.length;
			//console.log(queriedItems);
			this.currentResponseList = [];

			queriedItems.forEach(element => {
				if(element['questionid'] == this.currentQuestion){
					this.currentResponseList.push(element);
				}
			});
	});
  }

  goToNextQuestion(){

    this.showVoteResults = '0';

    this.nextQuestion();

    this.toggleQuestion();
    
    this.lastVideo = this.firstVideo;
    this.currentVideo = this.secondVideo;

    /*this.sources = new Array<Object>();
    this.sources.push({
      src: this.currentVideo,
      type: 'video/mp4'
    });*/

    this.db.list('/responses', ref=> ref.orderByChild('session').equalTo(this.session_id)).valueChanges(['child_added','child_changed']).subscribe(queriedItems => {
      //this.userCount = queriedItems.length;
      //console.log(queriedItems);
      this.currentResponseList = [];

      queriedItems.forEach(element => {
        if(element['questionid'] == this.currentQuestion){
          this.currentResponseList.push(element);
        }
      });
    });

  }

  showVotes(){
  	this.showVoteResults = '1';
  }


  onPlayerReady(api:VgAPI) {
  	this.api = api;

  	this.api.play();

  	this.api.getDefaultMedia().subscriptions.ended.subscribe(
  	    () => {
  	        // Set the video to the beginning
  	        //this.api.getDefaultMedia().currentTime = 0;
  	        //this.currentVideo = this.secondVideo;
  	        this.api.pause();

  	        
  	        this.toggleQuestion();
  	        //this.nextQuestion();

  	        this.lastVideo = this.firstVideo;
      		this.currentVideo = this.secondVideo;

  	        this.sources = new Array<Object>();
  		    this.sources.push({
  		      src: this.currentVideo,
  		      type: 'video/mp4'
  		    });

  		    this.api.getDefaultMedia().subscriptions.canPlay.subscribe(
  		    	() => {
  		    		this.api.play();
  		    	});

  	        //alert('video ended');
  	    }
  	);
  }


}
