import { Component, Input, OnInit, OnDestroy, Renderer2, ViewEncapsulation  } from '@angular/core';

import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';

import { Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

import {AuthService} from '../auth/auth.service';

import { SessionService } from '../session/session.service';
import { Session } from '../session/session.model';

class Sessions {
    constructor(public title:string, public location:string, public isActive:boolean, public id:string ) { }
}

class UserData{
  public sex;
  public race;
  public age;
  constructor(){
    
  }
  
}

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class UsersComponent implements OnInit, OnDestroy {

  title = 'Users Area';

  public sessions: Observable<any[]>;

  newURL = '';
  userRef: AngularFireList<any>;

  sessionList: Session[];

  userData;

  userState: string;
  @Input() public hasUserAnswered: string;

  constructor(db: AngularFireDatabase,public router: Router, route: ActivatedRoute, public renderer: Renderer2, public authService: AuthService, private sessionService: SessionService) {
      this.userRef = db.list('/users');

      console.log(this.authService.currentUserId);
      console.log(this.authService.isUserAnonymousLoggedIn);
      console.log(this.hasUserAnswered);


      //this.userData = new UserData;
      //this.hasUserAnswered = 'no';
      //this.userState = 'gender';
      console.log(this.userData);
      console.log(this.userState);

      console.log(this.hasUserAnswered);
  }

  ngOnInit() {
    var x = this.sessionService.getData();
    console.log(x);
    x.snapshotChanges(['child_changed','child_added']).subscribe(item=>{
      this.sessionList = [];
      item.forEach(element => {
         var y = element.payload.toJSON();

         if(y['hasSessionEnded'] === false){
           y["$key"] = element.key;
           this.sessionList.push(y as Session);
         }
         
      });

      if(!this.authService.isUserAnonymousLoggedIn){
        this.userData = new UserData;
        this.userState = 'gender';
        this.hasUserAnswered = 'no';
      }else{
        this.hasUserAnswered = 'yes';
      }
      console.log(this.sessionList);

      console.log(this.authService.currentUserId);
      console.log(this.authService.isUserAnonymousLoggedIn);
      console.log(this.hasUserAnswered);
    });

    this.renderer.addClass(document.body, 'session-users');
    
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'session-users');
  }

  recordRace(race,event:any){
    var els = document.querySelectorAll('.ansopt.active');
    //Strip active class
    for (var i = 0; i < els.length; i++) {
      els[i].classList.remove('active');
    }

    this.renderer.addClass(event.target,"active");

    this.userData.race = race;
  }

  recordGender(gender,event:any){
    var els = document.querySelectorAll('.ansopt.active');
    //Strip active class
    for (var i = 0; i < els.length; i++) {
      els[i].classList.remove('active');
    }

    console.log(gender);

    this.renderer.addClass(event.target,"active");

    this.userData.sex = gender;
    //this.userState = 'age';
  }

  recordAge(age,event:any){
    var els = document.querySelectorAll('.ansopt.active');
    //Strip active class
    for (var i = 0; i < els.length; i++) {
      els[i].classList.remove('active');
    }

    this.renderer.addClass(event.target,"active");
    
    this.userData.age = age;
    //this.userState = 'race';
  }

  submitAns(){
    if(this.userState == 'gender'){

      this.userState = 'age';

    }else if(this.userState == 'age'){

      this.userState = 'race';
    
    }else if(this.userState == 'race'){
    
      this.hasUserAnswered = 'yes';
      this.userState = '';

      console.log(this.authService.currentUserId);
      console.log(this.authService.isUserAnonymousLoggedIn);
      console.log(this.hasUserAnswered);
    
    }
  }

  loginNoStore(session){
    console.log(this.authService.currentUserId);
      console.log(this.authService.isUserAnonymousLoggedIn);
      console.log(this.hasUserAnswered);
    this.newURL = '/session/'+session;

    this.router.navigateByUrl( this.newURL , { skipLocationChange:false } );
  }

  login(session) {
    console.log(this.authService.currentUserId);
      console.log(this.authService.isUserAnonymousLoggedIn);
      console.log(this.hasUserAnswered);

  	this.authService.anonymousLogin().then((result) => {
        //Add user to list with session ID

        //console.log(this.authService.userInfo);
        
        this.userRef.push({ 
          session: session,
          uid: this.authService.userInfo.user.uid,
          age: this.userData.age,
          sex: this.userData.sex,
          race: this.userData.race,
          s1_q1: '',
          s1_q2: '',
          s2_q1: '',
          s2_q2: '',
          s3_q1: '',
          mm_1: '',
          mm_2: '',
          mm_3: '',
          mm_4: '',
          mm_5: ''
        });
      	//Setup New URL.
      	this.newURL = '/session/'+session;

      	this.router.navigateByUrl( this.newURL , { skipLocationChange:false } );

    });
  }

  logout(session) {
  	if(session == undefined){
  		//this.session_ID = '';
  	}else{
  		//this.session_ID = '';
  	}
  	
  	
    this.authService.userSignOut();
  }

}
