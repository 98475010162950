import { Component, Input, OnInit, OnDestroy, OnChanges, Renderer2, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';


import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';

import { Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '../auth/auth.service';

import { SessionService } from '../session/session.service';
import { Session } from '../session/session.model';

@Component({
  selector: 'app-admin-create-session',
  templateUrl: './admin-create-session.component.html',
  styleUrls: ['./admin-create-session.component.css'],
  encapsulation: ViewEncapsulation.None,
})

export class AdminCreateSessionComponent implements OnInit, OnDestroy {

  public sessions: Observable<any[]>;

  newURL = '';
  userRef: AngularFireList<any>;
  sessionList: Session[];
  sessionItem: AngularFireList<any>;

  sessionInfo = {
    sessionName:'',
    sessionLocation:'',
  }


  createResponseObject: string;

  constructor(public db: AngularFireDatabase,public router: Router, route: ActivatedRoute, public authService: AuthService, private sessionService: SessionService, private renderer: Renderer2) { 
    
  	this.renderer.addClass(document.body, 'admin-create-session');

  	if(authService.isAdminLoggedIn){
      //console.log(authService.currentUserId);
      //console.log("LOGGED IN");
      //console.log(authService.isAdminLoggedIn);
    }else{
      //console.log(authService.isAdminLoggedIn);
      //console.log("NOT LOGGED IN");
 
      this.router.navigateByUrl( '/admin' , { skipLocationChange:false } );
    }
  }

  ngOnInit() {
  	this.sessionItem = this.sessionService.getData();

  	var x = this.sessionService.getData();
    //console.log(x);
    x.snapshotChanges().subscribe(item=>{
      this.sessionList = [];
      item.forEach(element => {
         var y = element.payload.toJSON();
         console.log(y);
         if(y['hasSessionEnded'] === false){
           y["$key"] = element.key;
           this.sessionList.push(y as Session);
         }
         
      });
    });
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'admin-create-session');
  }

  createSession(){
  	//console.log(this.sessionInfo);
  	//console.log(this.authService);
  	this.sessionItem.push({ 
      admin: this.authService.isAdminLoggedIn,
      admin_uid: this.authService.currentUserId,
      currentQuestion: false,
      isActive: false,
      isQuestionsActive: false,
      location: this.sessionInfo.sessionLocation,
      sessionName: this.sessionInfo.sessionName,
      hasSessionEnded: false
    });
  }

  login(session){
  	//console.log(this.authService.authState.email);
  	this.sessionItem.update(session,{ admin:this.authService.authState.email,isActive:true });

    //Setup New URL.
  	this.newURL = '/admin/'+session;

  	this.router.navigateByUrl( this.newURL , { skipLocationChange:false } );
  }

  

}
