import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import {AuthService} from '../auth/auth.service';

@Component({
  selector: 'app-admin-start-session',
  templateUrl: './admin-start-session.component.html',
  styleUrls: ['./admin-start-session.component.css']
})
export class AdminStartSessionComponent implements OnInit {

  constructor(public router: Router, route: ActivatedRoute, public authService: AuthService) {

  	if(authService.isAdminLoggedIn){
      //console.log(authService.currentUserId);
      //console.log("LOGGED IN");
      //console.log(authService.isAdminLoggedIn);
    }else{
      //console.log(authService.isAdminLoggedIn);
      //console.log("NOT LOGGED IN");
 
      this.router.navigateByUrl( '/admin' , { skipLocationChange:false } );
    }
  }

  ngOnInit() {
  }

}
