import { Injectable } from '@angular/core';

import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { Session } from './session.model'

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  sessionList: AngularFireList<any>;
  userList: AngularFireList<any>;

  currentSession: Session = new Session();

  constructor(private firebase: AngularFireDatabase) { }

  getData(){
  	this.sessionList = this.firebase.list('sessions');
  	return this.sessionList;
  }

  removeUserDataFromSession(sess: string){
  	this.userList = this.firebase.list('users',ref=> ref.orderByChild('session').equalTo(sess));
  	return this.userList;
  }

  updateSessionLogout(sess: Session){
  	this.sessionList.update(sess.$key,{
  		isActive : false,
  		admin : ''
  	});
  }

  updateSessionLogin(sess: Session,user: Object){
  	this.sessionList.update(sess.$key,user);
  }


}
