import { Component } from '@angular/core';

import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';

import { Observable } from 'rxjs';

class Book {
    constructor(public title) { }
}


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';

  public books: Observable<any[]>;

  constructor(db: AngularFireDatabase) {
        this.books = db.list('/sessions').valueChanges();

        //console.log(this.books);
  }

}
